import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../AppContext/AppContext';
import Logo from '../Logo/Logo';
import UserTools from '../UserTools/UserTools';

function Header(props: any) {
  const { state } = useContext(AppContext);
  const { user } = state;

  useEffect(() => {
    document.body.classList.add("body-dashboard");
    
    return () => {
       document.body.classList.remove("body-dashboard");
    }
  }, [])

  return (
    <header className="header">
      <div className="header__inner">
        <div className="header__logo">
          <Link to={user && user?.status == 'active' ? '/projects/' : '/verify/'}>
            <Logo />
          </Link>
        </div>
        <div className="header__nav"></div>
        {user && <UserTools />}
      </div>
    </header>
  );
}

export default Header;
