import { createContext } from 'react';

interface EditorContextType {
	project: any;
	setProject: (project: any) => void;
	page: {_id: string; html: string;};
	setPage: (page: Object) => void;
	loading: boolean;
	setLoading: (loading: boolean) => void;
	iframeRef: any;
	iframeLoaded: boolean;
	setIframeLoaded: (iframeLoaded: boolean) => void;
	cssSelector: string;
	setCssSelector: (cssSelector: string) => void;
	exportType: string;
	setExportType: (exportType: string) => void;
	selectorPopup: { open: boolean, position: any, current: any, parent: any };
	setSelectorPopup: (selectorPopup: Object) => void;
};

// set the defaults
const EditorContext = createContext<EditorContextType>({
	project: {},
	setProject: () => {},
	page: {_id: '', html: ''},
	setPage: () => {},
	loading: false,
	setLoading: () => {},
  iframeRef: null,
	iframeLoaded: false,
	setIframeLoaded: () => {},
	cssSelector: '',
	setCssSelector: () => {},
	exportType: '',
	setExportType: () => {},
	selectorPopup: { open: false, position: {}, current: {}, parent: {} },
	setSelectorPopup: () => {},
});

export default EditorContext;