import React, { useState, useContext, useCallback, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import AppContext from '../AppContext/AppContext';
import Modal from '../Modal/Modal';
import FormProject from '../Forms/FormProject';
import { useNavigate } from 'react-router-dom';
import Iconify from '../Iconify/Iconify';
import { IconButton, Tooltip, Button, Stack } from '@mui/material';

function Projects() {
  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [projectDeleteId, setProjectDeleteId] = useState('');
  const { state, dispatch } = useContext(AppContext);
  const { projects } = state;
  const [projectEdited, setProjectEdited] = useState({ _id: '', name: '' });
  const navigate = useNavigate();

  const getAllProjects = useCallback(async () => {
    try {
      const option = {
        method: 'GET',
        url: '/api/v1/projects',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await axios(option);
      if (res.data.data.projects) {
        const newProjects = res.data.data.projects.filter((project: any) => !project.deleted);
        const projectsRemaining = res.data.data.projectsRemaining;
        dispatch({ type: 'GET_ALL_PROJECTS', payload: newProjects });
        dispatch({ type: 'GET_PROJECTS_REMAINING', payload: projectsRemaining });
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getAllProjects();
  }, [getAllProjects]);

  const updateProject = useCallback(
    debounce(async () => {
      if (projectEdited._id === '') return;
      try {
        const option = {
          method: 'PUT',
          url: '/api/v1/projects/' + projectEdited._id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: projectEdited,
        };
        await axios(option);
      } catch (error) {
        console.log(error);
      }
    }, 300),
    [projectEdited]
  );

  const handleChangeProject = (e: any, id: any, prop: string) => {
    const newProjects = projects.map((project: any) => {
      if (!project.deleted) {
        if (project._id === id) {
          project[prop] = e.target.value;
          setProjectEdited(project);
        }
        return project;
      }
    });
    updateProject();
    dispatch({ type: 'GET_ALL_PROJECTS', payload: newProjects });
  };

  const handleClickProject = (e: any, id: any) => {
    let dataValue = (e.target as HTMLElement).getAttribute('data-key');
    if (dataValue) {
      navigate('/projects/' + id);
    }
  };

  const handleDeleteProject = async (id: any) => {
    try {
      const option = {
        method: 'DELETE',
        url: '/api/v1/projects/' + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      await axios(option);
      const newProjects = projects.filter((project: any) => project._id !== id && !project.deleted);
      dispatch({ type: 'GET_ALL_PROJECTS', payload: newProjects });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const projects = state.user ? state.projects : [];
    localStorage.setItem('projects', JSON.stringify(projects));
  }, [state]);

  return (
    <>
      <div className="projects">
        <div
          className="projects__item"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <div className="projects__item-inner">
            <div className="projects__item-name">
              + Add new project 
            </div>
            <div className="projects__item-description">
              Remaining: {state.projectsRemaining === -1 ? 'unlimited' : state.projectsRemaining}
            </div>
          </div>
        </div>
        {projects.map((project: any) => (
          !project.deleted && (
            <div
              className="projects__item"
              data-key={project._id}
              key={project._id}
              onClick={(e) => handleClickProject(e, project._id)}
            >
              <div
                className="projects__item-inner"
                onClick={(e) => handleClickProject(e, project._id)}
              >
                <div className="projects__item-name">
                  <input type="text" value={project.name} onChange={(e) => handleChangeProject(e, project._id, 'name')} />
                </div>
                <div className="projects__item-tools">
                  <div className="projects__item-tool">
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        onClick={() => {
                          setProjectDeleteId(project._id);
                          setOpenModalConfirm(true);
                        }}
                      >
                        <Iconify icon="ph:trash" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {project.url && (
                <div className="projects__item-url">
                  Domain: {project.url}
                </div>
              )}
            </div>
          )
        ))}
      </div>
      {openModal && (
        <Modal
          modalTitle={'Create a new project'}
          setOpenModal={setOpenModal}
          openModal={openModal}
          modalContent={<FormProject openModal={openModal} setOpenModal={setOpenModal} />}
        />
      )}
      {openModalConfirm && (
        <Modal
          modalTitle={'Confirm to delete this project'}
          setOpenModal={setOpenModalConfirm}
          openModal={openModalConfirm}
          modalContent={
            <div className="modal__content">
              <div className="login__error">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
                </svg>
                <span>If you delete this project, it will still be counted as a used site</span>
              </div>
              <br />
              <Stack direction="row" spacing={2} color="#3c6df1">
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    setOpenModalConfirm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => {
                    handleDeleteProject(projectDeleteId);
                    setOpenModalConfirm(false);
                  }}
                >
                  Delete
                </Button>
              </Stack>
            </div>
          }
        />
      )}
    </>
  );
}

export default Projects;
