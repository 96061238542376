export default function reducer(state: any, action: any) {
  switch (action.type) {
    case 'CURRENT_USER':
      return { ...state, user: action.payload };
    case 'UPDATE_USER':
      return { ...state, user: action.payload.data.user };
    case 'GET_ALL_PROJECTS':
      return { ...state, projects: action.payload };
    case 'GET_ONE_PROJECT':
      return { ...state, projects: action.payload };
    case 'CREATE_ONE_PROJECT':
      return { ...state, projects: [ ...state.projects, action.payload.project ] };
    case 'UPDATE_ONE_PROJECT':
      return {
        ...state,
        projects: state.projects.map((project: any) => 
          project._id === action.payload._id ? action.payload : project
        )
      };
    case 'DELETE_ONE_PROJECT':
      return {
        ...state,
        projects: state.projects.filter((project: any) => project._id !== action.payload)
      };
    case 'GET_PROJECTS_REMAINING':
      return { ...state, projectsRemaining: action.payload };
    case 'GET_LICENSE':
      return { ...state, license: action.payload };
    default:
      return state;
  }
}