import React, { useEffect, useState } from 'react';
import { Alert, IconButton, InputAdornment, Link, Snackbar, Stack, TextField } from '@mui/material';
import Iconify from '../Iconify/Iconify';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import GetCode from './GetCode';
import VerifyCode from './VerifyCode';
import './ForgotPassword.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import ChangePassword from './ChangePassword';

const ForgotPassword = (props: any) => {
    const navigate = useNavigate();
    const [emailForgot, setEmailForgot] = useState({ email: '' });
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState({ password: '', confirm_password: '', id: '' });
    const [isGetCode, setIsGetCode] = useState(true);
    const [isVerifyCode, setIsVerifyCode] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [passwordError, setPasswordError] = useState({ status: false, msg: '' });
    const [submitButtonText, setSubmitButtonText] = useState('Get code');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [loading, setLoading] = useState({ submit: false, resend: false, });
    const [alertText, setAlertText] = useState('');
    const [userID, setUserID] = useState('');
    const [count, setCount] = useState(6);
    const [running, setRunning] = useState(false);
    const [disableButton, setDisableButon] = useState(true);
    const [showRedirectButton, setShowRedirectButton] = useState(false);

    const handleSendCode = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isGetCode) {
            await createNewCode();
        } else if (isVerifyCode) {
            await handleVerifyCode();
        } else {
            await handleChangePassword();
        }

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailForgot({ ...emailForgot, [e.target.name]: e.target.value });
    }

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    }

    const passwordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
    }

    const createNewCode = async () => {
        setLoading({ ...loading, submit: true });
        try {
            const option = {
                method: 'POST',
                url: '/api/v1/auth/getCode',
                data: emailForgot,
            };
            const res = await axios(option);
            if (res.data.success) {
                setUserID(res.data.id);
                setIsVerifyCode(true);
                setIsGetCode(false)
                setSubmitButtonText('Verify Code');
                setCount(180);
                setRunning(true);
            } else {
                setAlertText(res.data.error);
                setSnackbarOpen(true);
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setLoading({ ...loading, submit: false });
        }
    }
    const handleVerifyCode = async () => {
        setLoading({ ...loading, submit: true });
        try {
            const option = {
                method: 'POST',
                url: '/api/v1/auth/verify',
                data: {
                    id: userID,
                    code: code
                }
            };
            const res = await axios(option);
            if (res.data.success) {
                setIsGetCode(false);
                setIsVerifyCode(false);
                setIsChangePassword(true);
                setSubmitButtonText('Change Password');
                setCount(6);
                setRunning(false);
            } else {
                setAlertText(res.data.error);
                setSnackbarOpen(true);
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setLoading({ ...loading, submit: false });
        }
    }

    const handleChangePassword = async () => {
        setLoading({ ...loading, submit: true });
        if (newPassword.password != newPassword.confirm_password) {
            setPasswordError({ ...passwordError, status: true, msg: "Password don't match!" })
            setLoading({ ...loading, submit: false });
            return;
        }
        try {
            newPassword.id = userID;
            const option = {
                method: 'POST',
                url: '/api/v1/auth/changePassword',
                data: newPassword
            };
            const res = await axios(option);
            if (res.data.success) {
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setAlertText('Password has been changed.');
                setSubmitButtonText('Redirect...');
                setCount(6);
                setRunning(true);
                setShowRedirectButton(true);
            } else {
                setSnackbarSeverity('error');
                setAlertText(res.data.error);
                setSnackbarOpen(true);
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setLoading({ ...loading, submit: false });
        }
    }

    const handleRedirect = () => {
        if (showRedirectButton) {
            navigate('/')
        }
    }

    const handleResendCode = async () => {
        setLoading({ ...loading, resend: true });
        try {
            const option = {
                method: 'POST',
                url: '/api/v1/auth/getCode',
                data: emailForgot
            };
            const res = await axios(option);
            if (res.data.success) {
                setSnackbarOpen(true);
                setSnackbarSeverity('success');
                setAlertText('Verification code has been sent. Please check your email.');
                setLoading({ ...loading, resend: false });
                setCount(180);
                setRunning(true);
                setDisableButon(true)

            } else {
                setAlertText(res.data.error);
                setSnackbarSeverity('error');
                setLoading({ ...loading, resend: false });
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setLoading({ ...loading, resend: false });
        }
    }

    useEffect(() => {
        let intervalId: any;
        if (running) {
            intervalId = setInterval(() => {
                setCount(prevCount => prevCount - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [running]);

    useEffect(() => {
        if (count === 0) {
            setRunning(false);
            if (isChangePassword) {
                navigate('/');
            } else if (isVerifyCode) {
                setDisableButon(false);
            }
        }
    }, [count]);
    return (
        <>
            <div className="login">
                <form className="login__form" onSubmit={handleSendCode}>
                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity={snackbarSeverity == 'error' ? 'error' : 'success'} variant="filled" sx={{ pt: 0, pb: 0 }} icon={false}>
                            {alertText}
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ ml: 2 }}
                                onClick={() => setSnackbarOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Alert>
                    </Snackbar>
                    {isChangePassword && (<ChangePassword change={passwordChange} error={passwordError} />)}
                    {isGetCode && (<GetCode change={handleChange} />)}
                    {isVerifyCode && (<VerifyCode change={handleCodeChange} />)}
                    <div className='login__back-to-home-button'><span onClick={() => navigate('/')} style={{cursor:'pointer'}}>  <KeyboardBackspaceIcon /> Back to home page</span></div>
                    <Stack direction="row" spacing={2} color="#3c6df1">
                        {!isGetCode && !isChangePassword && (
                            <LoadingButton
                                loading={loading.resend}
                                size="large"
                                fullWidth
                                type={'button'}
                                variant={'outlined'}
                                disabled={disableButton}
                                onClick={handleResendCode}
                            >
                                Resend Code
                                {running && (
                                    <span>&nbsp;{count}s</span>
                                )}
                            </LoadingButton>
                        )}
                        {!isChangePassword && (
                            <LoadingButton
                                loading={loading.submit}
                                size="large"
                                fullWidth
                                type={'submit'}
                                variant={'contained'}
                            >
                                {submitButtonText}
                            </LoadingButton>
                        )}

                        {isChangePassword && (
                            <LoadingButton
                                size="large"
                                fullWidth
                                type={showRedirectButton ? 'button' : 'submit'}
                                variant={'contained'}
                                onClick={handleRedirect}
                            >
                                {submitButtonText}
                                {showRedirectButton && (
                                    <span>{count}s</span>
                                )}
                            </LoadingButton>
                        )}
                    </Stack>
                </form>
            </div>
        </>
    );
}

export default ForgotPassword;