import React, { useState } from 'react';
import { Alert, IconButton, InputAdornment, Snackbar, Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VerifyCode = (props: any) => {
    const [snackbarOpen, setSnackbarOpen] = useState(true)
    return (
        <>
            <h1 className="login__title">Verify Code</h1>
            <div className='fogot-password__helper-text'>Please enter the code sent to your email to change your password.</div>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert variant="filled" sx={{ pt: 0, pb: 0 }} icon={false}>
                    Verification code has been sent. Please check your email.
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ ml: 2 }}
                        onClick={() => setSnackbarOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Snackbar>
            <TextField
                name="code"
                type="text"
                label="Enter code"
                autoFocus={true}
                required
                fullWidth
                onChange={props.change}
                autoComplete="off"
            />
        </>
    );
}

export default VerifyCode;