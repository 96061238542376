import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import debounce from 'lodash.debounce';
import AppContext from '../AppContext/AppContext';
import { useNavigate } from 'react-router-dom';
import Iconify from '../Iconify/Iconify';
import { IconButton, Tooltip, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';
import './project.scss';
import { Config } from '../../Config';
import Modal from '../Modal/Modal';

function Project() {
  const { projectId } = useParams();
  const [pages, setPages] = useState<any>([]);
  const [pageEdited, setPageEdited] = useState({ _id: '', name: '' });
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState<string>('');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [pageDeleteId, setPageDeleteId] = useState('');

  const getAllPages = useCallback(async () => {
    try {
      const option = {
        method: 'GET',
        url: '/api/v1/pages/project/' + projectId,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await axios(option);
      setPages(res.data.data.pages);
    } catch (error: any) {
      setError(error.response.data.error);
      setOpenSnackbar(true);
    }
  }, [projectId]);

  useEffect(() => {
    getAllPages();
  }, [getAllPages]);

  const updatePage = useCallback(
    debounce(async () => {
      if (pageEdited._id === '') return;
      try {
        const option = {
          method: 'PUT',
          url: '/api/v1/pages/' + pageEdited._id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: pageEdited,
        };
        await axios(option);
      } catch (error: any) {
        setError(error.response.data.error);
        setOpenSnackbar(true);
      }
    }, 300),
    [pageEdited]
  );

  const handleChangePage = (e: any, id: any) => {
    const newPages = pages.map((page: any) => {
      if (page._id === id) {
        page.name = e.target.value;
        setPageEdited(page);
      }
      return page;
    });
    updatePage();
    setPages(newPages);
  };

  const handleClickPage = (id: any) => {
    navigate(`/projects/${projectId}/${id}`);
  };

  const handleDeletePage = async (id: any) => {
    try {
      const option = {
        method: 'DELETE',
        url: '/api/v1/pages/' + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      await axios(option);
      const newPages = pages.filter((page: any) => page._id !== id);
      setPages(newPages);
    } catch (error: any) {
      setError(error.response.data.error);
      setOpenSnackbar(true);
    }
  };

  const handleChange = (e: any) => {
    setUrl(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const option = {
        method: 'POST',
        url: '/api/v1/pages',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          url: url,
          projectId: projectId,
        },
      };
      const res = await axios(option);
      setPages([...pages, res.data.data.page]);
      setUrl('');
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error.response.data.error);
      setOpenSnackbar(true);
    }
  };

  const handleUpdateHtml = async (htmlId: any, pageId: any) => {
    setFetching(pageId);
    try {
      const option = {
        method: 'PUT',
        url: '/api/v1/html/' + htmlId,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          htmlId: htmlId,
        },
      };
      const res = await axios(option);
      setPages(res.data.data.pages);
      setFetching('');
    } catch (error: any) {
      setFetching('');
      setError(error.response.data.error);
      setOpenSnackbar(true);
    }
  };

  return (
    <div className="project">
      <div className="header__project">
        <div className="header__project-inner">
          <Tooltip title="All Projects" arrow>
            <IconButton
              onClick={() => {
                navigate('/projects/');
              }}
              className="back-icon"
            >
              <Iconify icon="material-symbols:arrow-back-sharp" />
            </IconButton>
          </Tooltip>
          {/* <form className="form form-page" onSubmit={handleSubmit}>
            <input type="text" name="url" value={url} placeholder="https://yoursite.com/page-url" required onChange={handleChange} />
            <LoadingButton loading={loading} size="large" fullWidth type="submit" variant="contained">
              Add Page
            </LoadingButton>
          </form> */}
          <div className="header__project-notice">
            <img src={Config.PUBLIC_URL + '/chrome.svg'} alt="notice" />
            Add page by using <a href="https://chrome.google.com/webstore/detail/clonewebx-softliteio/kejifndpehkapckhogiecndmachaeilp" target="_blank">ClonewebX Chrome Extension</a>
          </div>
        </div>
      </div>
      <div className="pages">
        {pages.map((page: any) => (
          <div
            className={fetching === page._id ? 'pages__item fetching' : 'pages__item'}
            data-key={page._id}
            key={page._id}
          >
            <div
              className="pages__item-image"
              style={{
                backgroundImage: page.thumbnailUrl
                  ? 'url(' + Config.PUBLIC_URL + '/resources/' + page.thumbnailUrl + ')'
                  : '',
              }}
            >
              <div className="pages__item-center">
                <Button onClick={() => handleClickPage(page._id)} variant="contained">
                  View Page
                </Button>
                {/* <div className="pages__item-status">Fetching page...</div> */}
              </div>
              <div className="pages__item-tools">
                {/* <div className="pages__item-tool">
                  <Tooltip title="Refetching" arrow>
                    <IconButton
                      onClick={() => {
                        handleUpdateHtml(page.html, page._id);
                      }}
                      size="small"
                    >
                      <Iconify icon="radix-icons:reload" />
                    </IconButton>
                  </Tooltip>
                </div> */}
                <div className="pages__item-tool">
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      onClick={() => {
                        setPageDeleteId(page._id);
                        setOpenModalConfirm(true);
                      }}
                      size="small"
                    >
                      <Iconify icon="ph:trash" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="pages__item-name">
              <input type="text" value={page.name} onChange={(e) => handleChangePage(e, page._id)} />
            </div>
          </div>
        ))}
      </div>

      {pages.length === 0 && (
        <div className="steps">
          <div className="steps__heading">
            <h2 className="steps__heading-title">How to clone the webpage?</h2>
          </div>
          <div className="steps__list">
            <div className="steps__item">
              <div className="steps__item-title">
                <div className="steps__item-title-number">1</div>
                <div className="steps__item-title-text">Install ClonewebX Chrome Extension</div>
              </div>
              <div className="steps__item-desc">Ensure you are using the Google Chrome browser for optimal performance. <a href="https://chrome.google.com/webstore/detail/clonewebx-softliteio/kejifndpehkapckhogiecndmachaeilp" target="_blank">Click here to install the extension.</a></div>
            </div>
            <div className="steps__item">
              <div className="steps__item-title">
                <div className="steps__item-title-number">2</div>
                <div className="steps__item-title-text">Go to your target webpage</div>
              </div>
              <div className="steps__item-desc">Scroll down to the end of the target webpage to make sure all elements have loaded.</div>
            </div>
            <div className="steps__item">
              <div className="steps__item-title">
                <div className="steps__item-title-number">3</div>
                <div className="steps__item-title-text">Clone the Webpage</div>
              </div>
              <div className="steps__item-desc">Click on the ClonewebX extension icon located in the top-right corner of your browser. Choose the appropriate project you created earlier and click “Add Page.”</div>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={error}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
      
      {openModalConfirm && (
        <Modal
          modalTitle={'Confirm delete page'}
          setOpenModal={setOpenModalConfirm}
          openModal={openModalConfirm}
          modalContent={
            <div className="modal__content">
              <div className="modal__content-text">
                Are you sure you want to delete this page?
              </div>
              <br />
              <Stack direction="row" spacing={2} color="#3c6df1">
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    setOpenModalConfirm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => {
                    handleDeletePage(pageDeleteId);
                    setOpenModalConfirm(false);
                  }}
                >
                  Delete
                </Button>
              </Stack>
            </div>
          }
        />
      )}
    </div>
  );
}

export default Project;
