import React, { useContext } from 'react';
import AppContext from '../AppContext/AppContext';
import { Link } from 'react-router-dom';
import Iconify from '../Iconify/Iconify';
import UserPopover from '../UserPopover/UserPopover';
import { Tooltip, IconButton, Button } from '@mui/material';

export default function UserTools(props: any) {
  const { state, dispatch } = useContext(AppContext);
  const license = state.license;

  return (
    <div className="user-tools">
      { (license?.sitesRemaining === 0 || license?.productId !== 2273 ) && (
        <a href={'https://softlite.io/pricing/clonewebx/?email=' + state.user.email + '&first_name=' + state.user.first_name + '&last_name=' + state.user.last_name} target="_blank">
          <Button variant="contained" className="header__button">Upgrade</Button>
        </a>
      )}
      <a href='https://softlite.io/figwebx/' target='_blank' style={{padding: "10px"}} className="hidden-mobile">
        FigwebX
      </a>
      <a href='https://softlite.io/litemove/' target='_blank' style={{padding: "10px"}} className="hidden-mobile">
        Litemove
      </a>
      <a href='https://softlite.io/docs/clonewebx/' target='_blank' style={{padding: "10px"}}>
        Documents
      </a>
      <UserPopover />
    </div>
  );
}
