import React, { useState } from 'react';
import { TextField } from '@mui/material';

const GetCode = (props: any) => {
    return (
        <>
            <h1 className="login__title">Forgot password</h1>
            <p className='lost-password-note'>Lost your password? Please enter your email address. You will receive a verification code sent via email.</p>
            <TextField
                name="email"
                type="email"
                label="Email address"
                required
                fullWidth
                onChange={props.change}
                autoComplete="off"
                autoFocus={true}
            />

        </>
    );
}

export default GetCode;