import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../AppContext/AppContext';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

function FormProject(props: any) {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projectInput, setProjectInput] = useState({});
  const [upgrade, setUpgrade] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectInput({ ...projectInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const option = {
        method: 'POST',
        url: '/api/v1/projects',
        data: projectInput,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await axios(option);
      const { project } = res.data.data;
      dispatch({ type: 'CREATE_ONE_PROJECT', payload: res.data.data });

      if (state.projectsRemaining !== -1) {
        dispatch({ type: 'GET_PROJECTS_REMAINING', payload: state.projectsRemaining - 1 });
      }

      const projects = state.user ? state.projects : [];
      localStorage.setItem('projects', JSON.stringify(projects.concat(project)));

      setError(null);
      navigate(`/projects/${project._id}/`);
    } catch (error: any) {
      setError(error.response.data.error);
      
      if (error.response.status === 409) {
        setUpgrade(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="form form-project" onSubmit={handleSubmit}>
      <TextField name="name" label="Project Name" required fullWidth value={null} onChange={handleChange} />
      <TextField name="url" label="Source Domain (e.g clonesite.com)" required fullWidth value={null} onChange={handleChange} />
      <div className='margin-bottom-20'>All pages in this project must use this domain</div>
      {error && (
        <div className="login__error">
          <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
          </svg>
          <span>{error}</span>
        </div>
      )}
      {upgrade && (
        <a href={'https://softlite.io/pricing/clonewebx/?email=' + state.user.email + '&first_name=' + state.user.first_name + '&last_name=' + state.user.last_name} target="_blank">
          <LoadingButton loading={loading} size="large" fullWidth variant="contained">
            Upgrade License
          </LoadingButton>
        </a>
      )}
      {!upgrade && (
        <LoadingButton loading={loading} size="large" fullWidth type="submit" variant="contained">
          Create Project
        </LoadingButton>
      )}
    </form>
  );
}

export default FormProject;
