import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Panel from '../Panel/Panel';
import EditorContext from '../EditorContext/EditorContext';
import { finder } from '@medv/finder';
import debounce from 'lodash.debounce';

import '../../assets/css/editor.scss';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3364E3',
      dark: '#3364E3',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
});

function Editor() {
  let { projectId, pageId } = useParams();
  const [project, setProject] = useState<any>({});
  const [page, setPage] = useState<any>([]);
  const [error, setError] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cssSelector, setCssSelector] = useState<string>('');
  const [selectorPopup, setSelectorPopup] = useState<any>({ open: false, position: { x:null, y:null }, current: [], parent: [] });
  const [exportType, setExportType] = useState<string>('selector');
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);

  useEffect(() => {
    document.body.classList.add("body-editor");
    
    return () => {
       document.body.classList.remove("body-editor");
    }
  }, [])

  let iframeRef = useRef<HTMLIFrameElement | null>(null);

  const getPage = useCallback(async () => {
    try {
      const option = {
        method: 'GET',
        url: '/api/v1/pages/' + pageId,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await axios(option);
      setPage(res.data.data.page);
    } catch (error: any) {
      console.log(error);
    }
  }, [pageId]);

  useEffect(() => {
    getPage();
  }, [getPage]);

  const getProject = useCallback(async () => {
    try {
      const option = {
        method: 'GET',
        url: '/api/v1/projects/' + projectId,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await axios(option);
      setProject(res.data.data.project);
    } catch (error: any) {
      console.log(error);
    }
  }, [projectId]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  const editorValue = {
    project,
    setProject,
    page,
    setPage,
    error,
    setError,
    loading,
    setLoading,
    iframeRef,
    iframeLoaded,
    setIframeLoaded,
    cssSelector,
    setCssSelector,
    exportType,
    setExportType,
    selectorPopup,
    setSelectorPopup,
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      const iframeDocument = iframe.contentDocument;
      const iframeWindow = iframe.contentWindow;
        if (iframeDocument && iframeWindow) {
          const iframeBody = iframeDocument.getElementsByTagName('body')[0];
          const handleClickIframe = (event: MouseEvent) => {
            event.preventDefault();
            const element = event.target as HTMLElement;
            if (element.id !== 'clonewebx-selector-popup' && !element.closest('#clonewebx-selector-popup')) {
              const parent = element.parentElement;
              const selectorCurrent = finder(element, {
                root: iframeBody,
              });

              let selectorCurrentList = [selectorCurrent];

              let selectorParentList:any = [];
              let selectorParent = null;

              try {
                selectorParent = parent ? finder(parent, {
                  root: iframeBody,
                }) : null;
              } catch (error) {
                console.log(error);
              }
              
              if (selectorParent) {
                selectorParentList = [selectorParent];
              }

              // @ts-ignore
              iframeWindow.clonewebxSelectorPopup({ open: true, position: { x: event.pageX, y: event.pageY }, current: selectorCurrentList, parent: selectorParentList });
            }

            const selector = element.getAttribute('data-clonewebx-selector-item');
            if (selector) {
              setExportType('selector');
              setCssSelector(selector);
              // @ts-ignore
              iframeWindow.clonewebxSelectorPopup({open: false, position: { x: 0, y: 0 }, current: [], parent: []});
              // @ts-ignore
              iframeWindow.clonewebxSelectorRect({hide: true});
            }
          };

          const handleHoverIframe = (event: MouseEvent, mouseEvent: string) => {
            debounce((event) => {
              if (event.target.id !== 'clonewebx-selector-popup' && !event.target.closest('#clonewebx-selector-popup')) {
                if (exportType === 'selector') {
                  const element = event.target as HTMLElement;
                  const selector = finder(element, {
                    root: iframeBody,
                  });
                  iframeDocument.querySelectorAll(selector).forEach((item: any) => {
                    switch (mouseEvent) {
                      case 'mouseover':
                        // item.style.outline = '1px solid #3364E3';
                        // @ts-ignore
                        iframeWindow.clonewebxSelectorRect(item.getBoundingClientRect());
                        break;
                      case 'mouseout':
                        // item.style.outline = 'none';
                        break;
                    }
                  });
                }
              }
            }, 200)(event);
          };

          iframeBody.addEventListener('click', handleClickIframe);
          iframeBody.addEventListener('mouseover', (event) => {
            handleHoverIframe(event, 'mouseover');
          });
          iframeBody.addEventListener('mouseout', (event) => {
            handleHoverIframe(event, 'mouseout');
          });
          return () => {
            iframeBody.removeEventListener('click', handleClickIframe);
            iframeBody.removeEventListener('mouseover', (event) => {
              handleHoverIframe(event, 'mouseover');
            });
            iframeBody.removeEventListener('mouseout', (event) => {
              handleHoverIframe(event, 'mouseout');
            });
          };
        }
    }
  }, [iframeLoaded]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframeLoaded) {
      const iframeDocument = iframe.contentDocument;
      if (iframeDocument) {
        const iframeBody = iframeDocument.getElementsByTagName('body')[0];
        if (iframeBody) {
          
        }
      }
    }
  }, [iframeLoaded]);

  setTimeout(function(){
    if (!iframeLoaded) {
      window.frames[0].stop()
      setIframeLoaded(true);
      // window.postMessage({type: 'clonewebxIframeLoaded', data: page}, '*');
    }
  }, 10000);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <EditorContext.Provider value={editorValue}>
        <div className='editor'>
          <div className='editor-iframe-wrapper'>
            {page && page.html && (
              <iframe
                src={'/api/v1/html/' + page.html + '?v=' + (page.htmlVersion ? page.htmlVersion : 1)}
                title="iframe"
                className='editor-iframe'
                ref={iframeRef}
                onLoad={() => {
                  setIframeLoaded(true);
                  // window.postMessage({type: 'clonewebxIframeLoaded', data: page}, '*');
                }}
                sandbox="allow-scripts allow-same-origin allow-forms"
                referrerPolicy="origin"
              ></iframe>
            )}
            <Panel />
          </div>
        </div>
        
        {(!iframeLoaded) && (
          <div className="editor-loading">
            <CircularProgress />
            <div className="editor-loading__content">
              Loading experience...
            </div>
          </div>
        )}
      </EditorContext.Provider>
    </ThemeProvider>
  );
}

export default Editor;
