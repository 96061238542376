import React, { useState, useContext, useEffect } from 'react';
import { Alert, Divider, IconButton, InputAdornment, Snackbar, Stack, TextField, Typography } from '@mui/material';
import './verify.css';
import { LoadingButton } from '@mui/lab';
import AppContext from '../AppContext/AppContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { async } from 'q';

const VerifyCode = (props: any) => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AppContext);
    const { user } = state;
    const [loading, setLoading] = useState({ submit: false, resend: false });
    const [codeInput, setCodeInput] = useState({ id: user._id, code: '' });
    const [error, setError] = useState({ status: false, msg: '' });
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCodeInput({ ...codeInput, code: e.target.value });
    }
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading({ ...loading, submit: true });
        try {
            const option = {
                method: 'POST',
                url: '/api/v1/auth/active',
                data: codeInput,
            };
            const res = await axios(option);
            if (res?.data.data.status && res?.data.data.status == 'error') {
                setError({ ...error, status: true, msg: res?.data.data.msg });
            } else {
                dispatch({ type: 'CURRENT_USER', payload: res?.data.data });
                navigate('/projects/');
            }

        } catch (error: any) {
            console.log(error);
        } finally {
            setLoading({ ...loading, submit: false });
        }
    }

    const handleResend = async () => {
        setLoading({ ...loading, resend: true });
        try {
            const option = {
                method: 'POST',
                url: '/api/v1/auth/resend',
                data: codeInput,
            }
            await axios(option);
            setLoading({ ...loading, resend: false });
            setSnackbarOpen(true);
        } catch (error: any) {
            console.log(error);
        }
    }

    return (
        <>
            <div className='verification'>
                <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert variant="filled" sx={{ pt: 0, pb: 0 }} icon={false}>
                        Verification code has been sent. Please check your email.
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ ml: 2 }}
                            onClick={() => setSnackbarOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <form className='verification__form' onSubmit={handleSubmit}>
                    <h1 className='verification__name'>Hi, {user.first_name + ' ' + user.last_name}</h1>
                    <h1 className='verification__title'>Active Your Account</h1>
                    <div className='verification__helper-text'>Please enter the code sent to your email to activate your account.</div>
                    <TextField
                        name="verify_code"
                        type="text"
                        label="Enter code"
                        required
                        error={error.status}
                        helperText={error.msg}
                        autoFocus={true}
                        onChange={handleChange}
                        fullWidth
                        autoComplete="verify_code"
                    />
                    <Stack direction="row" spacing={2} color="#3c6df1">
                        <LoadingButton
                            loading={loading.resend}
                            size="large"
                            fullWidth
                            type="button"
                            variant="outlined"
                            onClick={handleResend}
                        >
                            Resend Code
                        </LoadingButton>
                        <LoadingButton
                            loading={loading.submit}
                            size="large"
                            fullWidth
                            type="submit"
                            variant="contained"
                        //onClick={handSignIn}
                        >
                            Active Account
                        </LoadingButton>
                    </Stack>
                </form>
            </div>
        </>
    );
}
export default VerifyCode;