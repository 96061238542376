import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Box } from '@mui/material';

export default function Iconify(props: any) {
  return <Box ref={props.ref} component={Icon} {...props} />;
}

Iconify.propTypes = {
  sx: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  color: PropTypes.string,
  height: PropTypes.number,
  style: PropTypes.object,
};
