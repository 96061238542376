import PropTypes from 'prop-types';

export default function Logo(props: any) {
  const { width, height, ...other } = props;
  return (
    <div className="logo" style={{ width: props.width + 'px', height: props.height + 'px' }} {...other}>
      <svg width="100%" height="100%" viewBox="0 0 310 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 17C0 13.6863 2.68629 11 6 11H47C50.3137 11 53 13.6863 53 17V58C53 61.3137 50.3137 64 47 64H6C2.68629 64 0 61.3137 0 58V17Z" fill="#3644EE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 0C13.6863 0 11 2.68629 11 6V8H47.5C52.1944 8 56 11.8056 56 16.5V53H58C61.3137 53 64 50.3137 64 47V6C64 2.68629 61.3137 0 58 0H17ZM51 53V16.5C51 14.567 49.433 13 47.5 13H11V47C11 50.3137 13.6863 53 17 53H51Z" fill="#3644EE"/>
      <path d="M34.9024 43.096L38.2844 46.516C37.0937 48.036 35.5231 49.2647 33.5724 50.202C31.6471 51.1393 29.5317 51.608 27.2264 51.608C25.1237 51.608 23.1984 51.266 21.4504 50.582C19.7024 49.8727 18.1824 48.8847 16.8904 47.618C15.6237 46.326 14.6357 44.8187 13.9264 43.096C13.2424 41.3733 12.9004 39.4733 12.9004 37.396C12.9004 35.3693 13.2551 33.4947 13.9644 31.772C14.6991 30.024 15.7124 28.5167 17.0044 27.25C18.2964 25.958 19.8164 24.97 21.5644 24.286C23.3377 23.5767 25.2631 23.222 27.3404 23.222C29.6457 23.222 31.7484 23.6907 33.6484 24.628C35.5484 25.5653 37.0937 26.794 38.2844 28.314L34.9024 31.734C34.0664 30.518 32.9897 29.5553 31.6724 28.846C30.3804 28.1113 28.9364 27.744 27.3404 27.744C25.6177 27.744 24.0724 28.162 22.7044 28.998C21.3617 29.834 20.2977 30.974 19.5124 32.418C18.7271 33.862 18.3344 35.5213 18.3344 37.396C18.3344 39.2707 18.7271 40.9427 19.5124 42.412C20.2977 43.856 21.3617 44.996 22.7044 45.832C24.0724 46.668 25.6177 47.086 27.3404 47.086C28.9364 47.086 30.3804 46.7313 31.6724 46.022C32.9897 45.2873 34.0664 44.312 34.9024 43.096Z" fill="white"/>
      <path d="M110.16 37.92L113.72 41.52C112.467 43.12 110.813 44.4133 108.76 45.4C106.733 46.3867 104.507 46.88 102.08 46.88C99.8667 46.88 97.84 46.52 96 45.8C94.16 45.0533 92.56 44.0133 91.2 42.68C89.8667 41.32 88.8267 39.7333 88.08 37.92C87.36 36.1067 87 34.1067 87 31.92C87 29.7867 87.3733 27.8133 88.12 26C88.8933 24.16 89.96 22.5733 91.32 21.24C92.68 19.88 94.28 18.84 96.12 18.12C97.9867 17.3733 100.013 17 102.2 17C104.627 17 106.84 17.4933 108.84 18.48C110.84 19.4667 112.467 20.76 113.72 22.36L110.16 25.96C109.28 24.68 108.147 23.6667 106.76 22.92C105.4 22.1467 103.88 21.76 102.2 21.76C100.387 21.76 98.76 22.2 97.32 23.08C95.9067 23.96 94.7867 25.16 93.96 26.68C93.1333 28.2 92.72 29.9467 92.72 31.92C92.72 33.8933 93.1333 35.6533 93.96 37.2C94.7867 38.72 95.9067 39.92 97.32 40.8C98.76 41.68 100.387 42.12 102.2 42.12C103.88 42.12 105.4 41.7467 106.76 41C108.147 40.2267 109.28 39.2 110.16 37.92Z" fill="black"/>
      <path d="M122.678 46.24H117.398V17.44H122.678V46.24Z" fill="black"/>
      <path d="M137.759 46.88C135.572 46.88 133.639 46.4267 131.959 45.52C130.279 44.5867 128.972 43.32 128.039 41.72C127.106 40.0933 126.639 38.2533 126.639 36.2C126.639 34.0933 127.106 32.24 128.039 30.64C128.972 29.04 130.279 27.7867 131.959 26.88C133.639 25.9467 135.572 25.48 137.759 25.48C139.946 25.48 141.879 25.9467 143.559 26.88C145.239 27.7867 146.546 29.04 147.479 30.64C148.412 32.24 148.879 34.08 148.879 36.16C148.879 38.24 148.412 40.0933 147.479 41.72C146.546 43.32 145.239 44.5867 143.559 45.52C141.879 46.4267 139.946 46.88 137.759 46.88ZM137.759 42.48C138.879 42.48 139.866 42.2133 140.719 41.68C141.572 41.1467 142.239 40.4133 142.719 39.48C143.199 38.52 143.439 37.4267 143.439 36.2C143.439 34.9467 143.199 33.8533 142.719 32.92C142.239 31.96 141.572 31.2133 140.719 30.68C139.866 30.1467 138.879 29.88 137.759 29.88C136.639 29.88 135.652 30.1467 134.799 30.68C133.946 31.2133 133.279 31.96 132.799 32.92C132.319 33.8533 132.079 34.9467 132.079 36.2C132.079 37.4267 132.319 38.52 132.799 39.48C133.279 40.4133 133.946 41.1467 134.799 41.68C135.652 42.2133 136.639 42.48 137.759 42.48Z" fill="black"/>
      <path d="M172.389 46.24H167.109V34.6C167.109 33.0267 166.749 31.8533 166.029 31.08C165.309 30.28 164.335 29.88 163.109 29.88C162.255 29.88 161.442 30.1067 160.669 30.56C159.895 31.0133 159.269 31.72 158.789 32.68C158.309 33.64 158.069 34.9067 158.069 36.48V46.24H152.789V26.12H158.029V32.36H157.389C157.629 30.5733 158.122 29.1867 158.869 28.2C159.642 27.1867 160.535 26.48 161.549 26.08C162.589 25.68 163.615 25.48 164.629 25.48C167.029 25.48 168.922 26.2133 170.309 27.68C171.695 29.1467 172.389 31.28 172.389 34.08V46.24Z" fill="black"/>
      <path d="M194.133 39.96L196.933 43.2C195.92 44.3467 194.613 45.2533 193.013 45.92C191.44 46.56 189.693 46.88 187.773 46.88C185.453 46.88 183.413 46.4267 181.653 45.52C179.893 44.6133 178.52 43.36 177.533 41.76C176.546 40.16 176.053 38.3067 176.053 36.2C176.053 34.0933 176.52 32.24 177.453 30.64C178.413 29.0133 179.706 27.7467 181.333 26.84C182.986 25.9333 184.84 25.48 186.893 25.48C188.893 25.48 190.666 25.9333 192.213 26.84C193.786 27.72 195.013 28.9467 195.893 30.52C196.773 32.0933 197.213 33.92 197.213 36V36.24H192.053V35.68C192.053 34.4533 191.826 33.3867 191.373 32.48C190.946 31.5733 190.346 30.8667 189.573 30.36C188.826 29.8533 187.933 29.6 186.893 29.6C185.8 29.6 184.84 29.8667 184.013 30.4C183.186 30.9067 182.533 31.6533 182.053 32.64C181.6 33.6 181.373 34.7467 181.373 36.08C181.373 37.4933 181.653 38.7067 182.213 39.72C182.8 40.7067 183.586 41.4667 184.573 42C185.586 42.5067 186.746 42.76 188.053 42.76C190.453 42.76 192.48 41.8267 194.133 39.96ZM197.213 37.8H179.453V34.44H196.573L197.213 36V37.8Z" fill="black"/>
      <path d="M224.478 46.24H219.238L214.158 30.88H215.598L210.598 46.24H205.358L199.078 26.12H204.278L208.838 42.96H207.518L212.798 26.12H217.198L222.478 42.96H221.118L225.678 26.12H230.758L224.478 46.24Z" fill="black"/>
      <path d="M250.657 39.96L253.457 43.2C252.443 44.3467 251.137 45.2533 249.537 45.92C247.963 46.56 246.217 46.88 244.297 46.88C241.977 46.88 239.937 46.4267 238.177 45.52C236.417 44.6133 235.043 43.36 234.057 41.76C233.07 40.16 232.577 38.3067 232.577 36.2C232.577 34.0933 233.043 32.24 233.977 30.64C234.937 29.0133 236.23 27.7467 237.857 26.84C239.51 25.9333 241.363 25.48 243.417 25.48C245.417 25.48 247.19 25.9333 248.737 26.84C250.31 27.72 251.537 28.9467 252.417 30.52C253.297 32.0933 253.737 33.92 253.737 36V36.24H248.577V35.68C248.577 34.4533 248.35 33.3867 247.897 32.48C247.47 31.5733 246.87 30.8667 246.097 30.36C245.35 29.8533 244.457 29.6 243.417 29.6C242.323 29.6 241.363 29.8667 240.537 30.4C239.71 30.9067 239.057 31.6533 238.577 32.64C238.123 33.6 237.897 34.7467 237.897 36.08C237.897 37.4933 238.177 38.7067 238.737 39.72C239.323 40.7067 240.11 41.4667 241.097 42C242.11 42.5067 243.27 42.76 244.577 42.76C246.977 42.76 249.003 41.8267 250.657 39.96ZM253.737 37.8H235.977V34.44H253.097L253.737 36V37.8Z" fill="black"/>
      <path d="M262.951 46.24H257.711V17.44H262.991V32.36H261.751C261.937 31.0533 262.377 29.88 263.071 28.84C263.791 27.8 264.724 26.9867 265.871 26.4C267.044 25.7867 268.377 25.48 269.871 25.48C271.711 25.48 273.391 25.9333 274.911 26.84C276.431 27.7467 277.631 29 278.511 30.6C279.417 32.2 279.871 34.0667 279.871 36.2C279.871 38.3067 279.417 40.16 278.511 41.76C277.604 43.36 276.391 44.6133 274.871 45.52C273.351 46.4267 271.644 46.88 269.751 46.88C268.017 46.88 266.524 46.5067 265.271 45.76C264.017 45.0133 263.071 44.0133 262.431 42.76C261.791 41.48 261.511 40.08 261.591 38.56L262.711 37.84C262.844 38.7467 263.177 39.56 263.711 40.28C264.244 40.9733 264.924 41.52 265.751 41.92C266.604 42.2933 267.524 42.48 268.511 42.48C269.684 42.48 270.711 42.2133 271.591 41.68C272.471 41.1467 273.151 40.4 273.631 39.44C274.137 38.48 274.391 37.4 274.391 36.2C274.391 34.9467 274.151 33.8533 273.671 32.92C273.217 31.96 272.577 31.2133 271.751 30.68C270.924 30.1467 269.924 29.88 268.751 29.88C267.631 29.88 266.631 30.1467 265.751 30.68C264.897 31.2133 264.217 31.96 263.711 32.92C263.231 33.8533 262.991 34.9867 262.991 36.32V41.2L262.951 41.48V46.24Z" fill="black"/>
      <path d="M288.365 46.24H282.005L293.045 30.6V32.24L282.965 17.64H289.325L296.445 28.52H295.365L302.485 17.64H308.765L298.765 32.24L298.805 30.6L309.805 46.24H303.405L295.325 34.28H296.445L288.365 46.24Z" fill="black"/>
      </svg>
    </div>
  );
}

Logo.defaultProps = {
  width: '130',
  height: '40',
};

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
