import { useState } from 'react';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import Iconify from '../Iconify/Iconify';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LoginByPassword = (props: any) => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <TextField
                name="email"
                type="email"
                label="Email address"
                required
                autoFocus={true}
                fullWidth
                onChange={props.change}
                autoComplete="email"
            />

            <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                required
                onChange={props.change}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                autoComplete="password"
            />
            <Stack className='clonewebx-forgot-password' direction="row" spacing={23} color="#3c6df1"><div onClick={() => navigate('/forgot-password')} style={{cursor:'pointer'}}>Forgot Password?</div></Stack>
        </>
    );
}

export default LoginByPassword;