import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

function DashboardBarLayout() {
  const darkTheme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: 'Inter',
      fontSize: 14,
    },
  });

  const { projectId } = useParams();
  
  return (
    <ThemeProvider theme={darkTheme}>
      <div className={ projectId ? 'dashboard dashboard--project' : 'dashboard' }>
        <Header />
        <div className="dashboard__content">
          <div className="dashboard__content__inner">
            <Outlet />
          </div>
        </div>
        <a href="https://m.me/softlite.io?ref=softlite.io" target="_blank">
          <img src="https://pafe.piotnet.com/wp-content/uploads/2024/06/messenger100.png" alt="Messenger Icon" style={{width: '50px',height: '50px', position: 'fixed',zIndex: 99999, right: '30px', bottom: '30px'}} />
        </a>
      </div>
    </ThemeProvider>
  );
}

export default DashboardBarLayout;