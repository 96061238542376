import { useContext, useEffect, useState } from 'react';
import { Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import Iconify from '../Iconify/Iconify';
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import IconGoogle from './icon-google.svg';
import axios from 'axios';
import AppContext from '../AppContext/AppContext';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const LoginByGoogle = (props: any) => {
    const { state, dispatch } = useContext(AppContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const login = useGoogleLogin({
        onSuccess: async codeResponse => {
            setLoading(true);
            await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    Authorization: `Bearer ${codeResponse.access_token}`,
                    Accept: 'application/json'
                }
            }).then(async res => {
                try {
                    const option = {
                        method: 'POST',
                        url: '/api/v1/auth/registerByGoogle',
                        data: {
                            first_name: res.data.given_name,
                            last_name: res.data.family_name,
                            email: res.data.email,
                            username: res.data.email,
                            avatar: res.data.picture
                        },
                    };
                    const register = await axios(option);
                    const { token, newUser } = register.data.data;
                    localStorage.setItem('token', token);
                    dispatch({ type: 'CURRENT_USER', payload: newUser });
                    navigate('/projects/');
                } catch (error: any) {
                    console.log(error);
                    setLoading(false);
                }
            });
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    return (
        <>
            <LoadingButton fullWidth size="large" onClick={() => login()} color="primary" variant="contained" className="login__button--google" loading={loading}>
                <img src={IconGoogle} />
                Continue with Google
            </LoadingButton>
        </>
    );
}
export default LoginByGoogle;