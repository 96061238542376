import React, { useState } from 'react';
import { IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
import Iconify from '../Iconify/Iconify';

const ChangePassword = (props: any) => {
    const [showPassword, setShowPassword] = useState({ password: false, confirm_password: false });
    return (
        <>
            <h1 className="chage-password__title">Change Your Password</h1>
            <p className='lost-password-note'>Please enter a new password and confirm the new password.</p>
            <TextField
                name="password"
                label="New Password"
                type={showPassword.password ? 'text' : 'password'}
                required
                onChange={props.change}
                inputProps={{minLength: 8}}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}   edge="end">
                                <Iconify icon={showPassword.password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                autoFocus={true}
                fullWidth
            />
            <TextField
                name="confirm_password"
                label="Confirm Password"
                type={showPassword.confirm_password ? 'text' : 'password'}
                required
                onChange={props.change}
                inputProps={{minLength: 8}}
                error={props.error.status}
                helperText={props.error.msg}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword({ ...showPassword, confirm_password: !showPassword.confirm_password })} edge="end">
                                <Iconify icon={showPassword.confirm_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
        </>
    );
}

export default ChangePassword;