import React, { useState, useContext, useEffect } from 'react';
import { IconButton, Button, Stack, InputAdornment, TextField, Snackbar, Alert, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import AppContext from '../AppContext/AppContext';
import { LoadingButton } from '@mui/lab';
import { InputLabel } from '@mui/material';
import Iconify from '../Iconify/Iconify';
import './profile.scss';
import { profile } from 'console';
import gravatarUrl from 'gravatar-url';
import { Config } from '../../Config';

function Profile() {
    const { state, dispatch } = useContext(AppContext);
    const user = state.user;
		const license = state.license;
    const [userProfile, setUserProfile] = useState({ first_name: user.first_name, last_name: user.last_name, username: user.username, email: user.email });
    const [loading, setLoading] = useState({ profile: false, password: false });
    const [disableButton, setDisableButton] = useState({ profile: true, password: true })
    const [showPassword, setShowPassword] = useState({ current_password: false, new_password: false, confirm_password: false });
    const [userUpdate, setUserUpdate] = useState({ id: user._id, first_name: user.first_name, last_name: user.last_name, username: user.username, email: user.email, avatar: user.avatar });
    const [passwordUpdate, setPasswordUpdate] = useState({ id: user._id, current_password: '', new_password: '', confirm_password: '' });
    const [error, setError] = useState({ password: false, confirm_password: false });
    const [errorMsg, setErrorMsg] = useState({ password: '', confirm_password: '' });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alert, setAlert] = useState('Profile updated.');
    const [avatar, setAvatar] = useState<File>();
    const [avatarPath, setAvatarPath] = useState(user.avatar)
    const [errorUpload, setErrorUpload] = useState({ status: false, text: '', class: 'profile-upload-helper hidden' });
    const [showCurrentPassword, setShowCurrentPassword] = useState(true);

    let menu = {
        links: [
            {
                id: 0,
                name: "General",
                to: "#general",
                className: "profile-sidebar__nav-link"
            },
            {
                id: 1,
                name: "License",
                to: "#license",
                className: "profile-sidebar__nav-link"
            },
        ],
        activeLink: 0
    };

    let avartarGravatar = '';

    try {
        avartarGravatar = user ? gravatarUrl(user.email, { size: 200 }) : '';
    } catch (error) {
        console.log(error);
    }

    useEffect(() => {
        if (user?.code == 0) {
            setShowCurrentPassword(false)
        }
        setAvatarPath(user?.avatar ? user.avatar : avartarGravatar);
    }, [avatar]);

    const [menuState, setMenuState] = useState(menu);
    const handleClick = (id: any) => {
        menuState.activeLink = id;
        setMenuState(menuState);
    }

		useEffect(() => {
			const currentHash = window.location.hash;

			// Find the index of the link with a matching 'to' value
			const index = menu.links.findIndex(link => link.to === currentHash);
	
			// Update the activeLink if a matching link is found
			if (index !== -1) {
				menuState.activeLink = index;
        setMenuState(menuState);
			}
		}, []);

    const handleChangeProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserUpdate({ ...userUpdate, [e.target.name]: e.target.value });
        setUserProfile({ ...userProfile, [e.target.name]: e.target.value })
        setDisableButton({ ...disableButton, profile: false });
    }
    const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
        setLoading({ ...loading, profile: true });
        e.preventDefault();
        const option = {
            method: 'POST',
            url: '/api/v1/auth/update',
            data: {
                info: JSON.stringify(userUpdate),
                avatar: avatar ? avatar : ''
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const res = await axios(option);
        setLoading({ ...loading, profile: false });
        setDisableButton({ ...disableButton, profile: true })
        setAlert('Profile updated.');
        setSnackbarOpen(true);
        dispatch({ type: 'UPDATE_USER', payload: res.data });
        if (!avatar) {
            setAvatar(undefined);
        }
    }
    const handleCancelButton = () => {
        setUserProfile({ ...userProfile, first_name: user.first_name, last_name: user.last_name, username: user.username, email: user.email });
        setAvatar(undefined);
        setDisableButton({ ...disableButton, profile: true })
        clearPassword();
    }
    const handleUpdatePassword = async (e: React.FormEvent<HTMLFormElement>) => {
        setLoading({ ...loading, password: true });
        e.preventDefault();
        if (passwordUpdate.new_password !== passwordUpdate.confirm_password) {
            setError({ ...error, confirm_password: true })
            setErrorMsg({ ...errorMsg, confirm_password: "Password don't match!" });
            setLoading({ ...loading, password: false });
            setDisableButton({ ...disableButton, password: true });
            return;
        }
        const option = {
            method: 'POST',
            url: '/api/v1/auth/updatePassword',
            data: passwordUpdate
        }
        const res = await axios(option);
        if (res.data.data.status && res.data.data.status == 'error') {
            setError({ ...error, password: true });
            setErrorMsg({ ...errorMsg, password: res.data.data.msg });
        } else {
            setAlert('Password updated.');
            setSnackbarOpen(true);
            clearPassword();
            setShowCurrentPassword(true);
        }
        setLoading({ ...loading, password: false });
        setDisableButton({ ...disableButton, password: true });
    }
    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoading({ ...loading, password: false });
        if (e.target.name !== 'current_password') {
            setError({ ...error, confirm_password: false });
            setErrorMsg({ ...errorMsg, confirm_password: '' });
        } else {
            setError({ ...error, password: false });
            setErrorMsg({ ...errorMsg, password: '' });
        }
        setPasswordUpdate({ ...passwordUpdate, [e.target.name]: e.target.value });
        setPasswordUpdate({ ...passwordUpdate, [e.target.name]: e.target.value });
        setDisableButton({ ...disableButton, password: false });
    }
    const clearPassword = () => {
        setPasswordUpdate({ ...passwordUpdate, current_password: '', new_password: '', confirm_password: '' })
    }

    const handleSelectAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorUpload({ ...errorUpload, status: false, class: 'profile-upload-helper hidden' });
        if (e.currentTarget?.files?.[0]?.size && e.currentTarget?.files?.[0]?.size > 4194304) {
            setErrorUpload({ ...errorUpload, status: true, text: "File size must be less than 4MB.", class: 'profile-upload-helper' });
        } else if (e.currentTarget?.files?.[0]?.type && !e.currentTarget?.files?.[0]?.type.includes('image/')) {
            setErrorUpload({ ...errorUpload, status: true, text: "Please upload image.", class: 'profile-upload-helper' });
        } else if (e.currentTarget?.files != undefined) {
            setDisableButton({ ...disableButton, profile: false });
            setAvatar(e.currentTarget?.files?.[0]);
        }
    }

    return (
        <div className='profile'>
            <div className='profile-sidebar'>
                <div className='profile-sidebar__inner'>
                    {menuState.links.map(item => {
                        return (
                            <a key={item.id} className={item.className + (item.id === menuState.activeLink ? ' active' : '')} onClick={() => handleClick(item.id)} href={item.to}>{item.name}</a>
                        );
                    })}
                </div>
            </div>
            {menuState.activeLink === 0 && (
                <div className='profile-content'>
                    <div className='profile-content__inner'>
                        <h2>Profile</h2>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                <Alert variant="filled" sx={{ pt: 0, pb: 0 }} icon={false}>
                                    {alert}
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        sx={{ ml: 2 }}
                                        onClick={() => setSnackbarOpen(false)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Alert>
                            </Snackbar>
                        </Stack>
                        <form className='profile-update' onSubmit={handleUpdate}>
                            <div className='profile-update__avatar'>
                                <div className='profile-update__avatar--input'>
                                    <input type="file" accept='image/*' name='avatar' id='avatar' onChange={handleSelectAvatar} style={{ display: 'none' }} />
                                    <img src={avatar ? URL.createObjectURL(avatar) : avatarPath} />
                                </div>
                                <div className="profile-update__avatar--button">
                                    <InputLabel htmlFor="avatar" style={{ display: 'inline-block' }}>Change avatar</InputLabel>
                                    <FormHelperText error={errorUpload.status} className={errorUpload.class}>{errorUpload.text}</FormHelperText>
                                </div>
                            </div>
                            <TextField
                                name="first_name"
                                type="text"
                                label="First Name"
                                required
                                fullWidth
                                onChange={handleChangeProfile}
                                value={userProfile.first_name}
                            />
                            <TextField
                                name="last_name"
                                type="text"
                                label="Last Name"
                                required
                                fullWidth
                                onChange={handleChangeProfile}
                                value={userProfile.last_name}
                            />
                            <TextField
                                name="email"
                                type="email"
                                label="Email"
                                required
                                fullWidth
                                onChange={handleChangeProfile}
                                value={userProfile.email}
                                disabled={true}
                            />
                            <Stack direction="row" spacing={2} color="#3c6df1">
                                <LoadingButton
                                    loading={loading.profile}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disabled={disableButton.profile}
                                >
                                    Update Profile
                                </LoadingButton>
                                <Button size="large" disabled={disableButton.profile} onClick={handleCancelButton} variant="outlined" className="profile__button--cancel">Cancel</Button>
                            </Stack>
                        </form>
                    </div>
                    <div className='profile-password__inner'>
                        <h2>Password</h2>
                        <form className='password-update' onSubmit={handleUpdatePassword}>
                            {showCurrentPassword &&
                                <TextField
                                    name="current_password"
                                    type={showPassword.current_password ? 'text' : 'password'}
                                    label="Current Password"
                                    required
                                    error={error.password}
                                    helperText={errorMsg.password}
                                    value={passwordUpdate.current_password}
                                    onChange={handleChangePassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword({ ...showPassword, current_password: !showPassword.current_password })} edge="end">
                                                    <Iconify icon={showPassword.current_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                />
                            }
                            <TextField
                                name="new_password"
                                label="New Password"
                                type={showPassword.new_password ? 'text' : 'password'}
                                required
                                onChange={handleChangePassword}
                                error={error.confirm_password}
                                value={passwordUpdate.new_password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword({ ...showPassword, new_password: !showPassword.new_password })} edge="end">
                                                <Iconify icon={showPassword.new_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                            <TextField
                                name="confirm_password"
                                label="Confirm Password"
                                type={showPassword.confirm_password ? 'text' : 'password'}
                                required
                                onChange={handleChangePassword}
                                error={error.confirm_password}
                                value={passwordUpdate.confirm_password}
                                helperText={errorMsg.confirm_password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword({ ...showPassword, confirm_password: !showPassword.confirm_password })} edge="end">
                                                <Iconify icon={showPassword.confirm_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                            <Stack direction="row" spacing={2} color="#3c6df1">
                                <LoadingButton
                                    loading={loading.password}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    disabled={disableButton.password}
                                >
                                    Change Password
                                </LoadingButton>
                                <Button size="large" disabled={disableButton.password} onClick={handleCancelButton} variant="outlined" className="profile__button--cancel">Cancel</Button>
                            </Stack>
                        </form>
                    </div>
                </div>
            )}

            {menuState.activeLink === 1 && (
                <div className='profile-content'>
                    <div className='profile-content__inner'>
                        <h2>License</h2>
												<div className='profile-license'>
													{license && (
														<>
															<div style={{marginBottom: '10px'}}>
																<strong>License Name:</strong> {license.licenseName}
															</div>
															<div style={{marginBottom: '10px'}}>
																<strong>Sites Remaining:</strong> {license.sitesRemaining}
															</div>
															{ license.expiration && (
																<div>
																	<strong>Expiration:</strong> {license.expiration}
																</div>
															)}
															{ (license.sitesRemaining === 0 || license.productId !== 2273 ) && (
																<>
																	<br/>
																	<a href={'https://softlite.io/pricing/clonewebx/?email=' + state.user.email + '&first_name=' + state.user.first_name + '&last_name=' + state.user.last_name} target="_blank">
																		<Button variant="contained" className="header__button">Upgrade</Button>
																	</a>
																</>
															)}
														</>
													)}
												</div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default Profile;